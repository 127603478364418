import { Caption, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import { SystemStyleObject } from '@styled-system/css';
import React, { Fragment } from 'react';

import { usePriceQuoteUnit } from './usePriceQuoteUnit';
import { Pricing } from '@AuroraTypes';
import { DiscountTooltip } from '@Pages/search-results/card/DiscountTooltip';

export type PriceProps = {
  pricing: Pick<Pricing, 'discountPresentation' | 'pricingPresentation'>;
  strikeThroughStyles?: SystemStyleObject;
};

export const Prices: React.FC<PriceProps> = ({ pricing, strikeThroughStyles }) => {
  const { priceQuoteUnit } = usePriceQuoteUnit();
  const { t } = useTranslation();

  if (!pricing.pricingPresentation) {
    return null;
  }

  return (
    <Fragment>
      {pricing.pricingPresentation.showStrikeThrough && (
        <div
          sx={{
            display: 'flex',
            ...strikeThroughStyles,
          }}
        >
          {pricing.pricingPresentation.discountPercentage && (
            <Caption
              variant="small"
              sx={{
                fontWeight: 'bold',
                color: 'actioncriticalDefault',
                marginRight: '4xs',
              }}
            >
              {pricing.pricingPresentation.discountPercentage}
            </Caption>
          )}
          <Caption
            variant="strikethrough"
            data-id="prices-previous"
          >
            <span
              sx={{
                color: 'textDimmedheavy',
                whiteSpace: 'nowrap',
              }}
            >
              {priceQuoteUnit === 'PER_PERSON' ? (
                <Fragment>
                  {pricing.pricingPresentation?.wasPerPerson} {t('priceQuoteUnit.perPersonShort')}
                </Fragment>
              ) : (
                pricing.pricingPresentation?.wasTotal
              )}
            </span>
          </Caption>
          <DiscountTooltip pricing={pricing} />
        </div>
      )}
      <Label
        variant="extralargebold"
        data-id="prices-total"
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {priceQuoteUnit === 'PER_PERSON' && pricing.pricingPresentation?.textPerPerson ? (
          <Fragment>
            {pricing.pricingPresentation?.textPerPerson}
            <span sx={{ fontSize: 'm', marginLeft: '4xs', lineHeight: 1 }}>
              {t('priceQuoteUnit.perPersonShort')}
            </span>
          </Fragment>
        ) : (
          pricing.pricingPresentation?.textTotal
        )}
      </Label>
    </Fragment>
  );
};
