import { Icon, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { OfferHotel } from '@AuroraTypes';
import { useDate } from '@Dates/useDateHook';

interface HolidaySummaryProps {
  showIcons?: boolean;
}

export const HolidaySummary: React.FC<
  HolidaySummaryProps & Pick<OfferHotel, 'nights' | 'checkInDate'>
> = ({ nights, checkInDate, showIcons = true }) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();

  return (
    <Fragment>
      <Label
        variant="small"
        sx={{
          display: 'block',
          marginBottom: '3xs',
        }}
      >
        {showIcons && (
          <Icon
            name="Content/PlaceAirport"
            size="20"
            sx={{
              verticalAlign: 'middle',
              marginRight: '3xs',
            }}
          />
        )}
        {t('flightsIncluded')}
      </Label>

      <Label
        variant="small"
        data-id="summary-dates"
        sx={{
          display: 'block',
        }}
      >
        {showIcons && (
          <Icon
            name="Content/InputDate"
            size={['20', '16']}
            sx={{
              verticalAlign: 'middle',
              marginRight: '3xs',
            }}
          />
        )}
        {localizedFormat(checkInDate)}
        {' ∙ '}
        {t('nightsLabel', { count: nights })}
      </Label>
    </Fragment>
  );
};
