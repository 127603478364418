import { Icon, Label, Tooltip } from '@loveholidays/design-system';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';

type DiscountMerchandisingProps = ClassNameProps & {
  copy: string;
};

const DiscountMerchandisingMessagingContent: React.FC<DiscountMerchandisingProps> = ({ copy }) => (
  <Label
    as="p"
    variant="small"
    sx={{ maxWidth: '300px' }}
  >
    {copy}
  </Label>
);

export const DiscountMerchandisingMessagingTooltip: React.FC<DiscountMerchandisingProps> = ({
  copy,
  className,
}) => (
  <Tooltip
    className={className}
    content={<DiscountMerchandisingMessagingContent copy={copy} />}
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Icon
      size="16"
      name="Markers/Tooltip"
    />
  </Tooltip>
);
