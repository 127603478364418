import { SystemStyleObject } from '@styled-system/css';
import React from 'react';

import { DiscountMerchandisingMessagingTooltip } from './DiscountMerchandisingSash';
import { HotelDiscountType, Pricing } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

const tooltipStyle: SystemStyleObject = {
  marginLeft: '3xs',
};

type PricingData = Pick<Pricing, 'pricingPresentation' | 'discountPresentation'>;

type CampaignTooltipProps = ClassNameProps & {
  copy: string;
};

const DiscountMapping: Record<HotelDiscountType, React.FC<CampaignTooltipProps> | undefined> = {
  SUPPLIER: DiscountMerchandisingMessagingTooltip,
  DEFAULT: undefined,
};

export type DiscountTooltipProps = {
  pricing: PricingData;
};

export const DiscountTooltip: React.FC<DiscountTooltipProps> = ({
  pricing: { pricingPresentation, discountPresentation },
}) => {
  const showDiscountHelp = useFeatureFlag('ShowDiscountHelp');
  const packageHasDiscount = pricingPresentation?.tooltipText || discountPresentation;

  if (!showDiscountHelp || !packageHasDiscount) {
    return null;
  }

  if (discountPresentation) {
    const Tooltip = DiscountMapping[discountPresentation.type];

    if (Tooltip) {
      return (
        <Tooltip
          copy={discountPresentation?.tooltipText!}
          sx={tooltipStyle}
        />
      );
    }
  }

  return (
    <DiscountMerchandisingMessagingTooltip
      copy={pricingPresentation?.tooltipText!}
      sx={tooltipStyle}
    />
  );
};
